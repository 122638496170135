<template>
    <div class="legend">
        <h3> {{ title }} </h3>
        <div class="legend-row" v-for="cat in data" v-bind:key="cat.label">  
            <span v-if="cat.color" class="legend-color" v-bind:style="{background: cat.color}"></span>
            <span v-else-if="cat.marker" class="legend-marker" v-html="cat.marker.options.html"> </span>
            <label> {{cat.label}} </label>
        </div>
        <div v-if="notes" v-html="notes"> </div> 
    </div>
</template>


<script>

export default {
    name: 'Legend',
    props: {
        title: String,
        data: {
            type: Array,
            default: function() { [{label: "Empty", color: "#000000"}] },
            required: true,
            validator: function (values) {
                return values.length > 0 && values.every(v => v.label != null && (v.color != null || v.marker != null));
            }
        },
        notes: String,
    },
}
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/legend.scss";
</style>
