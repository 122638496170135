<template>
    <div id="global-wrapper">
        <nav class="navbar is-primary">
            <div class="navbar-brand">
                <div class="navbar-item full-logo"> <img class=" white-filter" :src="logo"> </div>
                <h2 class="navbar-item is-size-3"> Garawe IDP Knowledge Base </h2>
                <a class="navbar-burger" data-target="navMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div class="navbar-menu" id="navMenu">
                <div class="navbar-end">
                    <div class="navbar-item">
                        <div class="is-clickable" v-on:click="recenter">
                            <icon-home :width="'2rem'" :height="'2rem'"/>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        
        <div id="main-wrapper">
            <InfoPanel ref="infoPanel" 
                :radioLegends="choices" 
                :legends="legends" :width="panelWidth" 
                :campProps="campProps"
                :campPhotos="campPhotos"
                :stats="globalMetrics"
                :layerFilters="layerFilters"
                v-on:go-back="resetView"
                @click-button-select="handlePropViewChange"
                @filter-value-change="handleLayerFilterValueChange"
                @filter-active-change="handleLayerFilterActiveChange"
                @heatmap-change="handleHeatMapChange"
            >
            <template v-slot:footer>
                <div class="mb-2"> <b> Sources </b>: {{ sources }} </div>
                <div class="buttons"> <button class="button is-a" @click="download"> Download data </button> </div>
            </template>
            </InfoPanel>
            <div id="map-wrapper">
                <div class="title-wrapper" v-if="campProps !== null"> 
                    <h1 class="title-camp is-size-4 mr-2"> <b>{{ campProps['Name'] }} </b> {{ campProps['Settlement type'] }} Profile - Garawe, Puntland, December 2021 </h1> 
                </div>
                <div id="map"></div>
            </div>
        </div>
        
    <TabbedInfo ref="infos" :title="infoTitle" :values="infoValues"/>
    </div>
</template>

<script>
import InfoPanel from '@/components/InfoPanel.vue';
import TabbedInfo from '@/components/TabbedInfo.vue';
import logo from '@/assets/img/logo_giz.png';

import CustomMarker from '@/util/CustomMarker.js'
import * as d3 from 'd3';
import L from 'leaflet';
import 'leaflet-hash';
import 'leaflet-basemaps';
import 'leaflet-spin';
import MiniMap from 'leaflet-minimap';
import {baseLayers, cartoLight} from '@/util/baseLayers.js';
import { Layer, LayerCollection} from '@/util/layer.js';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import HeatmapOverlay from 'heatmap.js/plugins/leaflet-heatmap/leaflet-heatmap';

delete L.Icon.Default.prototype._getIconUrl;

// make webpack import the marker
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const schoolMarker = new CustomMarker({
    iconPath: require('@/assets/img/icons/school.svg'),
    whiteIcon: true,
    color: "#548bff",
});
const hospitalMarker = new CustomMarker({
    iconPath: require('@/assets/img/icons/hospital.svg'),
    whiteIcon: true,
    color: "#f86767",
});

const heatmapConfig = {
    // radius should be small ONLY if scaleRadius is true (or small radius is intended)
    // if scaleRadius is false it will be the constant radius used in pixels
    "radius": 10,
    "maxOpacity": .8,
    // scales the radius based on map zoom
    "scaleRadius": false,
    // if set to false the heatmap uses the global maximum for colorization
    // if activated: uses the data maximum within the current map boundaries
    //   (there will always be a red spot with useLocalExtremas true)
    "useLocalExtrema": false,
    // which field name in your data represents the latitude - default "lat"
    latField: 'lat',
    // which field name in your data represents the longitude - default "lng"
    lngField: 'lng',
    // which field name in your data represents the data value - default "value"
    valueField: 'count'
};

import computeArea from '@/util/geomArea.js';
// new name -> Original name
const mapping = {
    'Name': 'IDP Settlement',
    'Settlement type': 'type',
    'Settlement population': 'Individuals',
    'Settlement size': 'Settlement size',
    'Population change': 'Population Characteristics',
    'Female headed households': '?',
    'Number of disabled people': 'Disabled people',
    'Dominant clan family': 'Majority Clan 1 Parent',
    'Dominant clan': 'Majority Clan 1',
    'Secondary clan family': 'Minority Clan 1 Parent',
    'Secondary clan': 'Minority Clan 1',
    'Age of settlement': 'Settlement Age',
    'Notable population features': '?',
    'Settlement committee': '?',
    'Distance from Garawe centre': 'Distance to Garawe Centre (km)',
    'Settlement tenure': 'IDP site tenure type',
    'Land Tenure Agreement with': 'Who keeps the agreement',
    'End of tenure': 'Agreement end-date',
    'LTA type': 'If yes, type of agreement',
    'LTA held where': '?',
    'Eviction risk': 'Eviction Risk',
    'Typical shelter construction': 'Shelter Types',
    'Typical rental cost': '?',
    'Proportion of households with private latrines': 'proportion houses with private latrines',
    'Health': 'Main health actors',
    'Education': 'Main education actors',
    'WASH': 'Main WASH actors',
    'Type of water': 'Access to water',
    'Livelihoods': 'Main livelihoods actors',
    'Shelter': '?',
    'Electricity': 'Access to electricity',
    'Markets': 'Access to markets',
    'Finance': 'Access to finance',
    'Police': 'Police presence? (or community police)',
    'Protection': 'Main Protection Actors',
    'Legal aid': '?',
    'Cash assistance': '?',
    'Vulnerability': 'Vulnerability',
    'Vulnerability score': 'vulnerability_mean',
    'Vulnerability variance': 'vulnerability_variance', 
    'Vulnerability median': 'vulnerability_median',
    'Food insecure households' : 'Prevalence of food insecure households',
    'HH not adopting coping strategies': 'HH not adopting coping strategies',
    'Stress strategies': 'Stress strategies',
    'Crisis strategies': 'Crisis  strategies',
    'Emergency strategies': 'Emergency  strategies',
    'Reduced Coping Strategy Index (rCSI)': 'reduced Coping Strategy Index (rCSI)', 
};

import vulnerability from '@/assets/layers/vulnerability.json';
import camps from '@/assets/layers/camps.json';
import pois from '@/assets/layers/pois.json';

let vulnerabilityData = vulnerability.features.map(feat => {
    const props = feat.properties;
    return {lat: props.latitude, lng: props.longitude, value: props['VAF_score']};
});

vulnerabilityData = {
    max: Math.max(...camps.features.map(f => f.properties['vulnerability_mean'])),
    data: vulnerabilityData
};

const columns = Object.keys(camps.features[0].properties);
const dataForExport = camps.features.map(feat => {
    return Object.values(feat.properties);
});
dataForExport.splice(0, 0, columns);
function poisDisplay(point, latlng) {
    let marker;
    if (point.properties['Feature_'].includes('MCH')) marker = L.marker(latlng, {icon: hospitalMarker});
    else marker = L.marker(latlng, {icon: schoolMarker});
    marker.bindPopup((l) => {
        return l.feature.properties['Feature_'];
    }, {className: 'basic-popup'})
    return marker;
}

const mappingAges = {
    '(Age 0 - 4)': '0 - 4',
    '(Age 5 - 17)': '5 - 17',
    '(Age 18 - 59)': '18 - 59',
    '(60+)': '60+',
};

const ageCategories = [ "(Age 0 - 4)",  "(Age 5 - 17)", "(Age 18 - 59)", "(60+)"];
function computeDemographics(layer) {
    const totalWomen = layer.properties['# females in household'];
    const totalMen = layer.properties['# males in household'];
    const totPop = totalWomen + totalMen;
    const formatter = d3.format(".0%");
    return [totPop, ['Males', 'Females'].reduce((acc, gender) => {
        const key = formatter((gender == 'Males' ? totalMen : totalWomen) / totPop) + ' ' + gender
        acc[key] = ageCategories.map(age => {
            return {
                category: mappingAges[age],
                value: (layer.properties[gender + ' ' + age])/ totPop,
            }
        }, {});
        return acc;
    }, {})];
}

const clusterCoords = [
    {label: 'Jilab settlements', center: [8.3940, 48.5105]},
    {label: 'Washington settlements', center: [8.4222, 48.5155]}
];

const disabilityProps = ["Male difficuly seeing?", "Female difficult seeing?", "Male difficulty hearing?", "Female difficulty hearing?", "Male difficulty comprehending?", "Female difficulty comprehending?", "Male difficult mobility?", "Female difficult mobility?", "Male difficult dressing?", "Female difficulty dressing?", "Male difficulty remembering?", "Female difficulty remembering?"];
function generateMetrics(layer) {
    const area = computeArea(layer.geometry);
    let [totPop, demographicData] = computeDemographics(layer);
    if (totPop == 0) totPop = layer.properties['Individuals'];
    const metrics = {
        'Settlement size (km²)': area / 1000000,
        'Population density (ppl/km²)': layer.properties['Individuals'] / (area / 1000000),
        'Number of disabled people': disabilityProps.reduce((acc, prop) => {
            return acc + layer.properties[prop]
        }, 0),
        'demographicData': demographicData,
        'Settlement population': totPop,
    }
    metrics['Disabled people (%)'] = metrics['Number of disabled people'] / layer.properties['Individuals'];
    return metrics;
}

function generateGlobalMetrics(geojson) {
    const globalMetrics = geojson.features.reduce( (accum, layer) => {
        const pop = parseInt(layer.properties['Settlement population']);
        console.log(pop, layer.properties['Name'])
        accum['Host community / camp population'] += pop;
        accum.sortedPops.push(pop);
        accum.sortedAreas.push(layer.properties['Settlement size (km²)']);
        accum.sortedPopsDensities.push(layer.properties['Population density (ppl/km²)']);
        return accum
    }, {
        'Host community / camp population': 0,
        'Garawe estimated population': 250000,
        sortedPops: [],
        sortedAreas: [],
        sortedPopsDensities: [],
    });
    globalMetrics.sortedPops.sort((a, b) => a - b).reverse();
    globalMetrics.sortedAreas.sort((a, b) => a - b).reverse();
    globalMetrics.sortedPopsDensities.sort((a, b) => a - b).reverse();
    geojson.features.map(feat => {
        feat.properties['Settlement population rank'] = globalMetrics.sortedPops.findIndex((elem) => elem == feat.properties['Settlement population']) + 1;
        feat.properties['Settlement size rank'] = globalMetrics.sortedAreas.findIndex((elem) => elem == feat.properties['Settlement size (km²)']) + 1;
        feat.properties['Population density (ppl/km²) rank'] = globalMetrics.sortedPopsDensities.findIndex((elem) => elem == feat.properties['Population density (ppl/km²)']) + 1;
    });
    ['sortedPops', 'sortedAreas', 'sortedPopsDensities'].forEach(prop => delete globalMetrics[prop]);
    return globalMetrics;
}


function finalProperties(layer) {
    const computed = generateMetrics(layer);
    const mapped = Object.entries(mapping).reduce((accum, [newKey, propKey]) => {
        if (propKey === '?') return accum;
        accum[newKey] = layer.properties[propKey];
        return accum;
    }, {});
    return {...mapped, ...computed};
}


// ‘value' is String => radio, Array => checkbox 
const radios = {
    "Settlements": [{
        title: 'Settlements',
        data: [ 'Settlement type', 'Vulnerability score', 'Dominant clan family', 'Secondary clan family', 'Population density (ppl/km²)', 'Settlement size (km²)', 'Number of disabled people'],
        value: 'Settlement type'
    }],
};

const campPhotonameMapping = {
    "Ajuuran": 'Kaam Ajuuran',
    'Alkhayrat': 'Alkhayraat',
    'Alla Amin': 'Al Amin',
    'AllaWakil': 'Allawakiil',
    'Allawakil': 'Allawakiil',
    'Banadir': 'Banaadir',
    'Camp Ajuuran': 'Kaam Ajuuran',
    'Wabari village': "Waberi",
    'Camp Waberi': 'Kaam Waaberi',
    'Waberi Settlement': 'Kaam Waaberi',
    'Camp Yaman': 'Kaam Yaman',
    'Yaman': 'Kaam Yaman',
    "Jiingaddaha": "Kaamka Jiingadda",
    'Jiingadda': 'Kaamka Jiingadda',
    'Hiiraan': 'Kaam Hiiraan', 
    'Hodan Village': 'Hodan', 
    "Isra' Village": "Isra'",
    'Israac Village': "Isra'",
    'Kaam Riiga': 'Kaamka Riiga',
    'Kaam4': 'Kaam 4',
    'LaanCaali': 'Laan Cali Firin',
    'Lafobarakato': 'Lafo Barkato', 
    'Shabelle': 'Shabeelle',
    'Siliga': 'Kaam Siliga',
    'Jawle 2': 'Jowl Two',
    "Kaam Bundo": "Kaam Buundo",
};

export default {
    name: 'Map',
    props: {
        'countryCode': String,
    },
    components: {
        InfoPanel, TabbedInfo
    },
    data() {
        return {
            choices: radios['Settlements'],
            legends: [],
            layerFilters: [],
            infoTitle: null,
            infoValues: {},
            vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            isInit: false,
            panelWidth: '27rem',
            campProps: null,
            campPhotos: [],
            globalMetrics: {}
        }
        
    },
    
    created() {
        this.sources = "Altai Consulting, Camp Coordination and Camp Management (CCCM) Cluster June 2021 mapping exercise";
        this.logo = logo;
        const photos = require.context('@/assets/img/photos', false, /\.jp[e]?g/);
        this.photos= photos.keys().map(key => {
            const resolved = photos(key);
            const name = key.replace(/\.jp[e]?g/, '').substring(2) // remove extension and ./
            for (let [photoName, realName] of Object.entries(campPhotonameMapping)) {
                if (name.includes(photoName) && !name.includes(realName)) {
                    return [name.replace(photoName, realName), resolved];
                }
            }
            return [name, resolved]
        });
    },

    mounted() {
        // get config
        import(`../assets/${this.countryCode}.json`).then( config => {
            this.config = config.default;
            this.initMap();
        });
        camps.features.forEach(layer => {
            layer.properties = finalProperties(layer);
        });
        this.globalMetrics = generateGlobalMetrics(camps);
    },

    methods: {
        initMap() {
            this.map = L.map('map', {
                zoomControl:true,
                maxZoom:19,
                minZoom:5,
                center: this.config.center,
                zoom: this.config.zoom,
            });
            L.control.scale({position: 'bottomright'}).addTo(this.map);
            new MiniMap(cartoLight, {position: 'topright', zoomLevelOffset: -6}).addTo(this.map);
            this.layers = new LayerCollection(this);
            this.defaultCursor = this.map._container.style.cursor;
            this.initLayers();
            
        },
        initLayers() {
            this.map.createPane('above');
            const labels = this.map.createPane('labels');
            labels.style.zIndex = 555;
            const baseMaps = L.control.basemaps({ 
                basemaps: baseLayers,
                position: 'bottomleft',
            });
            this.map.addControl(baseMaps);
            this.map.removeLayer(baseLayers[0]);
            this.map.addLayer(baseLayers[0]);
            this.campsLayerName = 'Settlements';
            const onEachFeature = (feature, layer) => {
                layer.on('click', () => this.displayDetail(layer, feature));
            }
            const geojson = L.geoJSON(camps, {onEachFeature: onEachFeature});
            const bounds = geojson.getBounds().pad(1);
            this.map.fitBounds(bounds);
            const hash = new L.Hash(this.map);
            hash.update();
            this.camps = new Layer(this.campsLayerName, geojson);
            this.layers.addLayer(this.camps, true);
            L.geoJSON(pois, {pointToLayer: poisDisplay}).addTo(this.map);

            this.layerFilters = this.layers.getFilters();
            this.isInit = true;
            this.getLegends();
            clusterCoords.forEach(def => {
                new L.CircleMarker(def.center, {radius: 1, opacity: 0} )
                    .bindTooltip(def.label, {permanent: true, opacity: 1, className: "map-label cluster", direction: "center", offset: [0, 13] })
                    .addTo(this.map);
            });
            this.map.on('zoomend', () => {
                const zoomLevel = this.map.getZoom();
                if (zoomLevel < 14) document.documentElement.style.setProperty('--cluster-font-size', `${zoomLevel * 2}px`)
            })
            this.heatmapLayer = new HeatmapOverlay(heatmapConfig);
            // this.heatmapLayer.addTo(this.map);
            this.heatmapLayer.setData(vulnerabilityData);
        },

        handlePropViewChange(title, value) {
            this.updateLayerAndLegend(title, value);
        },
        updateLayerAndLegend(layerName, selectedProp) {
            this.layers.getLayer(layerName).setStyle(selectedProp);
            this.getLegends();
        },
        displayDetail(layer, features) {
            const filterName = this.layers.getLayer(this.campsLayerName)._filters['Name']; 
            filterName.active = false;
            filterName.value = "";
            const bounds = layer.getBounds().pad(0.5);
            // reset style on all layers
            this.camps.setStyle(this.choices[0].value);
            layer.setStyle({weight: 5, color: '#444444', fillColor:'#ff9f6f'})
            this.panelWidth = '60%';
            
            this.$nextTick(() => {
                this.map.invalidateSize();
                this.map.flyToBounds(bounds);
            });
            this.campProps = features.properties;
            this.campPhotos = this.getPhotos(features.properties['Name']);
        },
        handleLayerFilterValueChange(layerName) {
            this.layers.performFilter(layerName);
        },
        handleLayerFilterActiveChange(layerName, prop, state) {
            this.layers.updateFilterActive(layerName, prop, state);
        },
        handleHeatMapChange(showHeatmap) {
            if (showHeatmap) {
                // this.camps.remove(this.map);
                this.camps.setGlobalStyle({opacity: 1, fillOpacity: 0.3, weight: 2});
                this.map.addLayer(this.heatmapLayer);
            } else {
                this.map.removeLayer(this.heatmapLayer);
                this.camps.addTo(this.map);
                this.updateLayerAndLegend(this.campsLayerName, this.choices[0].value);
            }
        },
        recenter() {
            this.map.setView(this.config.center, this.config.zoom);
        },
        resetView() {
            this.camps.setStyle(this.choices[0].value);
            this.panelWidth = '27rem'; 
            this.campProps = null;
            this.$nextTick(() => {
                this.map.invalidateSize();
                this.recenter();
            });
        },
        getPhotos(campName) {
            return this.photos.filter((photoObj) => photoObj[0].includes(campName))
                .map(([name, path]) => {
                    return [name.replace(campName, '').replace(/[V-v]illage|[S-s]ettlement/g, '').replace(/_/g, ' ').replace(/-/g, '').replace(/\s+/g, ' ').trim(), path]
                })
        },

        getLegends() {
            this.legends = this.layers.getLegends();
            this.legends.push({title: 'Markers', data: [{label: 'School', marker: schoolMarker}, {label: 'Health Clinic', marker: hospitalMarker}]})
        },

        download() {
            const wb = XLSX.utils.book_new();
            wb.Props = { Title: "Garawe data export",
                Author: "Altai Consulting",
                CreatedDate: new Date(),
            };
            wb.SheetNames.push("Data");
            const ws = XLSX.utils.aoa_to_sheet(dataForExport);
            wb.Sheets["Data"] = ws;
            const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            const toBuf = (wb) => {
                var buf = new ArrayBuffer(wb.length); //convert wb to arrayBuffer
                var view = new Uint8Array(buf);  //create uint8array as viewer
                for (var i=0; i < wb.length; i++) view[i] = wb.charCodeAt(i) & 0xFF; //convert to octet
                return buf;    
            };
            saveAs(new Blob([toBuf(wbout)],{type:"application/octet-stream"}), 'garawe_data.xlsx');
        }
    },
    watch: {
    },
    // depending on browser, blur sometimes do not occur
    beforeRouteLeave (to, from, next) {
        this.$el.blur();
        next();
    }
}
</script>
<style lang="scss">
@import '@/style/scss/components/map';
</style>