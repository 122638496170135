<template>
    <div class="tab-info">
        <h2 class="popup-title is-size-3 " v-if="title"> {{ title }} </h2>
        <div class="popup-content">
            <table >
            <tr v-for="formatted of formattedTab['tabbed']" :key="formatted.label" >
                <td> 
                    <component :is="formatted.icon" :height="'1.3rem'" :width="'1.3rem'" v-if="formatted.icon"/>
                    <span> {{ formatted.label }} </span>
                </td>
                <td align="right" v-html="formatted.formatted"></td>
            </tr>
            </table>
            <div class="long" v-for="formatted of formattedTab['long']" :key="formatted.label">
                <h3>  <component :is="formatted.icon" v-if="formatted.icon"/> {{ formatted.label }} </h3>
                <span v-html="formatted.formatted">  {{ formatted.formatted }} </span>
            </div>
        </div>
    </div>
</template>
<script>
import {formatObject} from '@/util/formatting.js';
const sectionMapping = {0: 'tabbed', 1: 'long'};

export default {
    name: 'tabbed-infos',
    props: {
        values: Object,
        title: String,
    },
    computed: {
        formattedTab() { 
            const sections = {'long': [], 'tabbed': []};
            const formatted = formatObject(this.values);
            if (!formatted.length) return sections;
            formatted.forEach(item => sections[sectionMapping[item.section]].push(item));
            return sections
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/scss/_variables';

.leaflet-popup-content-wrapper div, .leaflet-tooltip .tab-info {
    display: flex;
}

.tab-info {
    font-size: 0.7rem;
    display: none;
    max-height: 30rem;
    flex-flow: column nowrap;
    margin: 0 auto;
    border-radius: 4px;
    .popup-title {
        height: 3rem;
    }
    h2, h3 { text-align: center; }
    h3 { margin:0 0 5px 0; }
    .popup-content {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 5px;
        overflow: scroll;
    }
    table {
        width: 100%;
        position: relative;
        white-space: nowrap;
        margin-bottom: 1rem;
        tr td:first-child {
            min-width: 10rem;
        }
        border-collapse: collapse;
        tr:not(:first-child) { 
            border-top:1px #ddd solid;
        }
        tr td svg {
            vertical-align: -3px;
        }
    }
    .long {
        display: block;
        text-align: center;
        h3 {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
            color: $customerColor;
            svg { margin: 0 5px;}
        }
    }
}
</style>
