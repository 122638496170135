<template>
    <div class="side-panel" v-bind:style="{'flex-basis': width}">
        <div class="panel-content is-flex is-flex-direction-column is-justify-content-space-between">
            <slot name="header"> </slot>
            <CampInfo v-on="$listeners"  v-if="campProps !== null" :props="campProps" :photos="campPhotos"/>
            <div v-else>
                <div class="stats">
                    <h3 class="is-size-4"> Global statistics </h3>
                    <span v-for="(value, key) in stats" :key="key">
                        <b>{{ key }}</b>: {{ formatSimple(value) }} <br>
                    </span>
                </div>
                 <div v-if="layerFilters.length" class="mt-4">
                    <label class="label"> Search by name: <input class="input" type="text" :value="layerFilters[0].filters['Name'].value" @input="handleValueChange($event, 'Name')"> </label>
                    <div class="">
                        <label class="label"> Filter by vulnerability</label>
                        <div class="select">
                            <select :value="layerFilters[0].filters['Vulnerability'].value" @input="handleValueChange($event, 'Vulnerability')">
                                <option :value="null"> All </option>
                                <option v-for="choice in layerFilters[0].filters['Vulnerability'].choices" :key="choice" :value="choice"> {{ choice }} </option>
                            </select>
                        </div>
                    </div>
                </div>
                <ButtonSelect title="" :data="['Show vulnerability heatmap']" v-model="heatmapValue" v-on:input="handleClickHeatmap"> </ButtonSelect>
                <ButtonSelect 
                    v-for="radioLegend in radioLegends" :key="radioLegend.title" 
                    :title="radioLegend.title" :data="radioLegend.data" v-model="radioLegend.value"
                    v-on:input="(val) => handleClickButton(radioLegend.title, val)">
                </ButtonSelect>
                <Legend v-for="legend in legends" :key="legend.title" v-bind="legend"> </Legend>
            </div>
            <div> <slot v-if="!campProps" name="footer"> </slot> </div>
        </div>
    </div>
</template>

<script>
import ButtonSelect from '@/components/ButtonSelect.vue';
import Legend from '@/components/Legend.vue';
import CampInfo from '@/components/CampInfo.vue';
import * as d3 from 'd3-format';

export default {
    name: 'info-panel',
    components: {
        ButtonSelect, Legend, CampInfo
    },
    beforeCreate() {
        this.formatSimple = d3.format(',.0f');
    },
    data() {
        return {
            heatmapValue: [],
        }
    },
    props: {
        form: String,
        radioLegends: Array,
        legends: Array,
        width: String,
        campProps: Object,
        campPhotos: Array,
        stats: Object,
        layerFilters: Array,
    },
    methods: {
        handleClickButton(title, value) {
            this.$emit('click-button-select', title, value)
        },
        handleValueChange(e, propName) {
            const prop = this.layerFilters[0].filters[propName];
            prop.active = true;
            prop.value = e.target.value;
            this.$emit('filter-value-change', this.layerFilters[0].layerName);
        },
        handleClickHeatmap() {
            this.$emit('heatmap-change', this.heatmapValue.length == 1);
        }
    },
}
</script>

<style lang="scss">
@import '@/style/scss/components/infoPanel';
</style>