import * as d3 from 'd3-format';

// const formatCurrency = d3.format("$.1f");
// const formatPercent = d3.format(".0%");
const formatNumber = d3.format(",.0f");
const formatNumber3 = d3.format(",.3f");
// const formatBool = (b) => `<span class="boolean ${b ? "yes": "no"}"> </span>`; 
// const formatLink = val => {
//     if (val.indexOf && val.indexOf('http') > -1) return `<a href=${val} target=_blank> ${val} </a>`;
//     return val;
// };
// const formatDate = (val) => val.substring(0, 10);
// variable_name => [format func, label_id (null for unchanged), icon, section (null if irrelevant)]
// the order matters, the resulting formatted object will be sorted
const valuesFormat = new Map([
    ['Name',                                [null, null, null, 0]],
    ['Population density (ppl/km²)',        [formatNumber, null, null, 0]],
    ['Settlement population',               [formatNumber, null, null, 0]],
    ['Vulnerability',                       [null, null, null, 0]],
    ['Vulnerability score',                 [formatNumber3, null, null, 0]],
]);
const orderedKeys = Array.from(valuesFormat.keys());

function constructFormatted(olabel, label, value, icon, section) {
    return {
        originalLabel: olabel,
        label: label,
        formatted: value,
        icon: icon,
        section: section
    }
}
/**
 * Format the given value according to the valuesFormat object, if the provided key exists
 * 
 * @param  {String} key     The key to search in valuesFormat
 * @param  {Any}    value   The value to format
 * @return {Object}         The formatted object
 */
function format(key, value) {
    if (!valuesFormat.has(key)) return constructFormatted(key, key, value, null, 0);
    const formats = valuesFormat.get(key);
    let formatted = value;
    let label = key;
    if (formats[0]) formatted = formats[0](formatted);
    if (formats[1]) label = formats[1];
    return constructFormatted(key, label, formatted, formats[2], formats[3]);
}

function formatObject(obj) {
    const formattedItems = Object.keys(obj).reduce( (prev,key) => {
        if (obj[key] == null) return prev;
        const formatted = format(key, obj[key]);
        prev.push(formatted);
        return prev;
    }, []);
    formattedItems.sort((a, b) => {
        return orderedKeys.indexOf(a.originalLabel) - orderedKeys.indexOf(b.originalLabel);
    });
    return formattedItems;

}


export {formatObject};