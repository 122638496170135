<template>
    <div v-if="props !== null" :class="isCamp ? 'camp': 'host'" >
        <section id="test">
            <h2 class="is-size-4"> <span> Main characteristics </span> <span class="back" v-on:click="goBack"> ← </span> </h2>
            <div class="columns">
            <p class="column">
                <span> <b> Settlement population</b>: {{ formatSimple(props['Settlement population']) }} ({{formatRank(props['Settlement population rank']) }})</span> <br>
                <span> <b> Settlement size</b>: {{ formatSquareKm(props['Settlement size (km²)']) }} ({{ formatRank(props['Settlement size rank']) }}) </span> <br>
                <span> <b> Population density</b>: {{ formatDensity(props['Population density (ppl/km²)']) }} ({{ formatRank(props['Population density (ppl/km²) rank']) }})</span> <br>
                <span> <b> Population change</b>: {{ props['Population change'] }} </span> <br>
                <span> <b> Disabled people</b>: {{ props['Number of disabled people'] == 0 ? 'Not known' : props['Number of disabled people'] }} <span v-if="props['Number of disabled people'] !== 0"> ({{ formatPct(props['Disabled people (%)']) }}) </span> </span> <br>
                <span> <b> Dominant clan</b>: {{ props['Dominant clan'] }} (Family: {{ props['Dominant clan family'] }}) </span> <br>
                <span> <b> Secondary clan</b>: {{ props['Secondary clan'] }} (Family: {{ props['Secondary clan family'] }})</span> <br>
                <span> <b> Age of settlement</b>: {{ props['Age of settlement'] }} </span> <br>
                <span> <b> Distance from Garawe centre</b>: {{ props['Distance from Garawe centre'] }} </span> <br>
            </p>
            <div class="column" v-if="!Object.keys(props.demographicData)[0].includes('NaN')">
                <p class="has-text-centered is-size-4"> <b> Demographics </b></p>
                <div id="pyramid"></div>
            </div>
            </div>
        </section>
        <section>
            <h2 class="is-size-4"> Land tenure and shelter </h2>
            <p>
                <span v-if="props['Settlement tenure']"> <b> Settlement tenure</b>: {{ props['Settlement tenure'] }} <br> </span> 
                <span v-if="props['Land Tenure Agreement with']"> <b> Land Tenure Agreement with</b>: {{ props['Land Tenure Agreement with'] }} <br> </span>
                <span v-if="props['LTA type']"> <b> LTA type</b>: {{ props['LTA type'] }} <br> </span> 
                <span v-if="props['Eviction risk']"> <b> Eviction risk</b>: {{ props['Eviction risk'] }} <br> </span> 
                <span v-if="props['Typical shelter construction']"> <b> Typical shelter construction</b>: {{ props['Typical shelter construction'] }}  <br> </span>
                <!-- <span v-if="!isCamp && props['Proportion of households with private latrines']"> <b> Proportion of households with private latrines</b>: {{ props['Proportion of households with private latrines'] }}  <br> </span> -->
            </p>
        </section>
        <div class="columns">
            <section class="column">
                <h2 class="is-size-4"> Access to services and actors </h2>
                <p>
                    <span v-if="isCamp && props['Health']"> <b> Health</b>: {{ props['Health'] }} <br> </span> 
                    <span v-if="isCamp && props['Education']"> <b> Education</b>: {{ props['Education'] }}<br> </span> 
                    <span v-if="props['WASH']"> <b> WASH</b>: {{ props['WASH'] }} <br> </span>
                    <span v-if="isCamp && props['Type of water']"> <b> Type of water</b>: {{ props['Type of water'].toLowerCase() == 'no' ? 'No access': props['Type of water'] }} <br> </span>
                    <span v-if="isCamp && props['Livelihoods']"> <b> Livelihoods</b>: {{ props['Livelihoods'] }} <br> </span> 
                    <span v-if="props['Electricity']"> <b> Electricity</b>: {{ props['Electricity'] }} <br> </span>
                    <span v-if="props['Markets']"> <b> Markets</b>: {{ props['Markets'] }} <br> </span> 
                    <span v-if="props['Finance']"> <b> Finance</b>: {{ props['Finance'] }} <br> </span> 
                    <span v-if="props['Police']"> <b> Police</b>: {{ props['Police'] }} <br> </span> 
                    <span v-if="props['Protection']"> <b> Protection</b>: {{ props['Protection'] }}<br>  </span> 
                </p>
            </section>
            <section class="column is-three-fifths">
                <h2 class="is-size-4"> Vulnerability </h2>
                <p class="content">
                    <span> <b> Vulnerability score</b>: {{ formatDecimal(props['Vulnerability score']) }} <br> </span> 
                    <span> <b> Vulnerability variance</b>: {{ formatDecimal(props['Vulnerability variance']) }} <br> </span>
                    <span> <b> Food insecure households</b>: {{ formatPct(props['Food insecure households']) }} <br> </span>
                    <span> <b> Livelihood strategies </b> </span>
                    <ul>
                        <li> <b> HH not adopting coping strategies: </b> {{ formatPct(props['HH not adopting coping strategies']) }} </li>
                        <li> <b> Stress strategies: </b> {{ formatPct(props['Stress strategies']) }} </li>
                        <li> <b> Crisis strategies: </b> {{ formatPct(props['Crisis strategies']) }} </li>
                        <li> <b> Emergency strategies: </b> {{ formatPct(props['Emergency strategies']) }} </li>
                    </ul>
                    <span> <b> Reduced Coping Strategy Index (rCSI) mean</b>: {{ formatDecimal(props['Reduced Coping Strategy Index (rCSI)']) }} <br> </span> 
                </p>
                <div id="cursorChart"></div>
            </section>
        </div>
        <section v-if="photos.length">
            <h2 class="is-size-4"> Photos </h2>
            <div class="is-flex is-flex-wrap-wrap is-justify-space-evenly is-align-items-center">
                <div class="photo has-text-centered m-1" v-for="photo in photos" :key="photo[0]">
                    <img :src="photo[1]">
                    <p> <b> {{ photo[0] }} </b> </p>
                </div>
            </div>
        </section>
        <!-- <div v-on:click="generateReport">Click me</div> -->
    </div>
</template>

<script>
import Vue from 'vue';
import * as d3 from 'd3';
import pyramidChart from '@/util/pyramidChart.js';

// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

export default {
    name: 'camp-info',
    components: {
    },
    beforeCreate() {
        this.formatSimple = d3.format(',.0f');
        this.formatDecimal = d3.format(',.3f')
        this.formatPct = d3.format('.2%');
        this.formatSquareKm = (val) => this.formatDecimal(val) + ' km²';
        this.formatDensity = (val) => this.formatSimple(val) + ' ppl/km²';
    },
    data() {
        return {
        }
    },

    props: {
        props: Object,
        photos: Array,
    },
    methods: {
        // https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
        formatRank(i) {
            const j = i % 10;
            const k = i % 100;
            if (j == 1 && k != 11) return i + "st";
            if (j == 2 && k != 12) return i + "nd";
            if (j == 3 && k != 13) return i + "rd";
            return i + "th";
        },
        goBack() {
            this.$emit('go-back');
        },
        // generateReport() {
        //     const doc = new jsPDF({
        //         orientation: 'landscape',
        //         unit: 'px'
        //     });
        //     html2canvas(document.getElementById('global-wrapper')).then(function(canvas) {
        //         // doc.addImage(canvas, 'jpeg', 0, 0, 1100, 1100)
        //         doc.addImage(canvas, 'JPEG', 0, 0)
        //         // document.body.append(canvas)
        //         doc.save('report.pdf');
        //     });
        // }
        cursorChart() {
            const padding = {top: 50, bottom: 20, left: 20, right: 20};
            const maxVulValue = 3;
            const widthCursorChart = 800;
            const heightCursorChart = 130;
            const markerSize = 10;
            const markerScale = 2;
            const finalMarkerSize = markerSize * markerScale;
            const scale = d3.scaleLinear().domain([0, maxVulValue]).range([padding.left, widthCursorChart - padding.right]);
            const axis = d3.axisTop(scale).ticks(3, "").tickSize(12);
            const vul = this.props['Vulnerability score'];
            const ratio = vul / maxVulValue;
            const xPosMarker = scale(vul);
            const colorScale = d3.interpolateHsl('#74c518', '#e04a04');
            const defs = d3.select('#svg-definitions');
            let mainGradient = d3.select('#mainGradient');
            if (!mainGradient.node()) {
                mainGradient = defs.append('linearGradient').attr('id', 'mainGradient');
                mainGradient.append('stop').attr('offset', '0').attr('stop-color', colorScale(0));
                mainGradient.append('stop').attr('offset', '0.5').attr('stop-color', colorScale(0.5));
                mainGradient.append('stop').attr('offset', '1').attr('stop-color', colorScale(1));
            }
            d3.select('#cursorChart').select('svg').remove();
            const svg = d3.select('#cursorChart').append("svg")
                .attr('xmlns', "http://www.w3.org/2000/svg")
                .attr("viewBox", `0 0 ${widthCursorChart + padding.left + padding.right} ${heightCursorChart + padding.top + padding.right}`);

            svg.append("g")
                .style("font-size", "20px")
                .attr("transform", `translate(0,${padding.top})`)
                .call(axis);

            svg.append("text")
                .attr('x', (widthCursorChart - padding.left - 100) / 2)
                .attr('y', padding.top - 30 )
                .attr('font-size', '24px' )
                .text('Vulnerability');

            svg.append('rect')
                .classed('filled', true)
                .attr('x', padding.left)
                .attr('y', padding.top)
                .attr('width',  widthCursorChart - (padding.left + padding.right))
                .attr('height', heightCursorChart - (padding.top + padding.bottom));

            const path = svg.append('path')
                .attr('d', `M ${scale(0)} ${padding.top - 2} l ${-markerSize} ${-markerSize} l ${markerSize * 2} 0 z`)
                .attr('fill', ``);

            path.transition().duration(1000)
                .ease(d3.easePolyOut.exponent(4))
                .attr("d", `M ${xPosMarker} ${padding.top - 2} l ${-finalMarkerSize} ${-finalMarkerSize} l ${finalMarkerSize * 2} 0 z`)
                .attrTween('fill', () => { 
                        // block the interpolation from going to the end
                        return function(t) {
                            if (t > ratio) t = ratio;
                            return colorScale(t);
                        }
                });

        }
    },
    computed: {
        isCamp() {
            return this.props['Settlement type'] === 'IDP Camp';
        }
    },
    watch: {
        props: {
            immediate: true,
            handler(newProps) {
                Vue.nextTick(() => {
                    this.cursorChart();
                    if(Object.keys(newProps.demographicData)[0].includes('NaN')) return;
                    pyramidChart('#pyramid', newProps.demographicData, {height: 250});
                })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/style/scss/variables';

.camp h2, .camp h1 {
    color: $customerColorLight;
}
.host h2, .host h1 {
    color: #3e7fe0;
}
.back {
    cursor: pointer;
}
.photo {
    height: max-content;
    max-width: 45%;
}

.filled {
    fill: url(#mainGradient);
}
</style>