import * as d3 from 'd3';

export default function pyramidChart(chartElem, data, {
    width = 460,
    height = 400,
    margin = {top: 10, right: 30, bottom: 10, left: 10},
    titleSize = 20,
    color = "#69b3a2",
    barPadding = 0.1,
    formatter = d3.format(".0%")
} = {}) {
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;
    d3.select(chartElem).select('svg').remove();
    const container = d3.select(chartElem)
            .append("svg")
            .attr("viewBox", `0 0 ${chartWidth + margin.left + margin.right} ${chartHeight + margin.top + margin.bottom + titleSize}`)
            .attr('xmlns', "http://www.w3.org/2000/svg")
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    const chartTitles = Object.keys(data);
    const categories = Object.values(data)[0].map(d => d.category);
    var y = d3.scaleBand()
        .domain(categories)
        .range([margin.top + titleSize, chartHeight - margin.bottom])
        .padding(barPadding)
        .round(true);

    const step =  (1 / 10) * chartWidth;
    const sizeChart = step * 4;
    const sizeLabels = step * 2;
    simpleChart(container, y, data[chartTitles[0]], sizeChart, sizeChart, color, formatter, false);
    simpleChart(container, y, data[chartTitles[1]], sizeChart, sizeChart + sizeLabels, color, formatter);
    const labels = container.append('g');
    const middleLabels = labels.selectAll("foreignObject")
        .data(data[chartTitles[0]])
        .enter();
    
    const addLabel = (elem, x, y, width, height, text, center = false, alignLeft = false) => {
        const label = elem.append("foreignObject")
            .attr("x", x)
            .attr("y", y)
            .attr("width", width)
            .attr("height", height)
            .append("xhtml:div")
                .text(text);
        if (center) label.attr('class', 'double-chart-text');
        if (alignLeft) label.style('text-align', 'right');
    }
    addLabel(middleLabels, sizeChart, d => y(d.category), sizeLabels, y.bandwidth(), d => d.category, true, false);
    addLabel(labels, 0, 0, sizeChart, titleSize, chartTitles[0], false, true);
    addLabel(labels, sizeChart + sizeLabels, 0, sizeChart, titleSize, chartTitles[1]);

}

function simpleChart(elem, yScale, data, width, offset, color, formatter, isRight = true) {
    const max = d3.max(data, d => d.value);
    const marginForText = 40;
    const xScale = d3.scaleLinear()
      .domain([0, max])
      .range([0, width - marginForText]);

    // rectangles
    const rects = elem.append('g')
        .attr("transform", `translate(${offset}, 0) ${isRight ? '' : 'scale(-1, 1)'}`)
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
            .attr("x", xScale(0))
            .attr("y", function(d) { return yScale(d.category); })
            .attr("width", 0)
            .attr("height", yScale.bandwidth())
            .style("fill", color);

    rects.transition().duration(1000)
        .ease(d3.easePolyOut.exponent(4))
        .attr("width", function(d) { return xScale(d.value); })
    // text
    const someText = elem.append('text').text('test');
    const textDims = someText.node().getBBox();
    someText.remove();
    elem.append('g')
        .attr('text-anchor', isRight ? 'end' : 'start')
        // .attr('transform', `translate(${isRight ? offset : 0}, 0)`)
        .attr('transform', `translate(${isRight ? offset : 0}, ${(yScale.bandwidth()/ 2) + (textDims.height / 3)})`)
    .selectAll("text")
    .data(data)
    .enter()
    .append("text")
        .attr("x", d => isRight ? xScale(d.value) + marginForText : xScale(max - d.value))
        .attr("y", d => yScale(d.category))
        .text(d => formatter(d.value))
}