<template>
    <div>
        <h3> {{ title }} </h3>
        <div v-for="choice in data" :key="choice" class="box-select">
            <input :type="inputType" :id="choice" :name="title" :value="choice" v-model="input">
            <label :for="choice"> {{ choice }} </label>
        </div>
    </div>
</template>


<script>

export default {
    name: 'button-select',
    props: {
        title: String,
        data: Array,
        value: [String, Array],
    },
    computed: {
        // implement model at parent level
        input: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        inputType() { return Array.isArray(this.value) ? 'checkbox' : 'radio'; }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/scss/_variables';    
div {
    margin: 1rem;
    & > h3 {
        color: $customerColor;
    }
}
</style>
